<template>
  <huong-dan-su-dung />
</template>

<script>
import HuongDanSuDung from '@/modules/mot-cua/components/pages/HuongDanSuDung.vue'

export default {
  name: 'TrangChu',
  components: {
    HuongDanSuDung,
  },
}
</script>

<style scoped></style>
