<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Tiêu đề hướng dẫn"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="huongDanSuDung"
        :rules="{ required: true }"
        name="huongDanSuDung"
      >
        <b-form-input
          id="input-1"
          ref="huongDanSuDung"
          v-model="dataForm.tenHuongDanSuDung"
          v-trim-input
          :autofocus="dataForm.id ? false : true"
          type="text"
          placeholder="Nhập tên hướng dẫn sử dụng"
          required
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
        <span class="label-noti-validate">{{ errorMessage }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Số thứ tự"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="soThuTu"
        :rules="{ required: true }"
        name="soThuTu"
      >
        <b-form-input
          id="input-1"
          ref="soThuTu"
          v-model.number="dataForm.soThuTu"
          type="number"
          placeholder="Nhập số thứ tự"
          required
          @input="notAllowMin"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trạng thái"
      label-for="input-1"
      class="grap-6"
    >
      <b-form-checkbox
        v-model="dataForm.status"
        switch
      />
    </b-form-group>
    <div>
      <div>
        <p class="text-mota">
          Mô tả
        </p>
      </div>

      <ckeditor
        v-model="dataForm.noiDungHuongDanSuDung"
        :editor="editor"
        :config="editorConfig"
        class="ckeditor-class"
      />

    </div>
  </div>
</template>
<script>

</script>
<script>
import '@/@core/scss/vue/libs/quill.scss'
import {
  BFormGroup, BFormInput, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
// import _cloneDeep from 'lodash/cloneDeep'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
// import { quillEditor } from 'vue-quill-editor'
// import { removeDiacritical } from '@/utils/index'
import addCssSemantic from '@/mixins/mixins'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak'
// import '@ckeditor/ckeditor5-build-classic/build/translations/vi'
import '@/utils/vi'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    // quillEditor,
    BFormInput,
    BFormCheckbox,
  },
  mixins: [addCssSemantic],
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tagsTemplate: [],
      editor: ClassicEditor,
      editorConfig: {
        language: 'vi',
        placeholder: 'Nhập mô tả...',
        plugins: [
          EssentialsPlugin,
          Base64UploadAdapter,
          LinkPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          AutoLink,
          Alignment,
          Table,
          TableToolbar,
          ListStyle,
          HeadingPlugin,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          Code,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
          PageBreak,
        ],
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'toggleTableCaption',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'uploadImage',
            'link',
            'alignment',
            'insertTable',
            'bulletedList',
            'numberedList',
            'pageBreak',
            'code',
            'undo',
            'redo',
          ],
        },
      },
    }
  },
  created() {},
  methods: {
    notAllowMin() {
      if (this.dataForm.soThuTu < 0) {
        this.dataForm.soThuTu = 0
      }
    },
  },
}
</script>
<style scoped lang="scss">
.text-mota{
  margin-bottom:3.5px;
}
</style>
